import React from 'react';
import styled from 'styled-components';

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      Powered by BitcoinCash (BCH) <br/>
      <a href="https://discover.cash">Learn More About BCH</a><br />
      <a href="https://helpme.cash">BCH Ecosystem</a>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  text-align: center;
  padding: 1em;
  background-color: #333;
  color: white;
  a {
    color: #00be89;
    text-decoration: none;

    &:hover {
      color: #00e4a5;
    }
  }
`;

export default Footer;