import React, { useEffect } from 'react';
import styled from 'styled-components';
import BCHLogo from '../assets/img/bch.png';
import { useNavigate } from 'react-router-dom';
import { CartItem } from '../types';
import { useCart } from './CartContext';

interface HeaderProps {
  //cartCount: number;
  //cart: CartItem[];
}

const Header: React.FC<HeaderProps> = ({  }) => {
  const navigate = useNavigate();
  const { cart } = useCart();

  useEffect(() => {
    // Save cart to localStorage whenever it changes
    localStorage.setItem('cart', JSON.stringify(cart));
  }, [cart]);

  const handleCheckout = () => {
    //localStorage.setItem('cart', JSON.stringify(cart));
    navigate('/checkout');
  };

  return (
    <HeaderContainer>
      <StyledTitle><a href="/">BCH.Quest</a></StyledTitle>
      <CartIcon onClick={handleCheckout}>
        🛒 <CartCount>{cart.length}</CartCount>
      </CartIcon>
      <StyledLogoDiv>
        <StyledBCHLogo size={80} />
      </StyledLogoDiv>
    </HeaderContainer>
  );
};

interface StyledLogoProps {
  size: number;
}

const HeaderContainer = styled.header`
  background-color: #0ba57a;
  color: white;
  text-align: center;
  padding: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 60px;
  border-bottom: 2px solid #fff;
  text-shadow: 3px 3px 5px rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
`;
const CartIcon = styled.div`
  position: absolute;
  top: 1em;
  right: 1em;
  font-size: 1.5em;
  cursor: pointer;
`;
const CartCount = styled.div`
  position: absolute;
  background-color: red;
  color: white;
  border-radius: 20px;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
  font-size: 18px;
  top: -0.5em;
  right: -0.5em;
`;
const StyledLogoDiv = styled.div`
  position: relative;
  border: 0px solid pink;
  width: 5px;
`;
const StyledTitle = styled.div`
  position: relative;
  z-index: 1;
  top: -15px;
  width: 100%;
  height: 30px;
  font-size: 32px;
  font-weight: 700;
  border: 0px solid red;
  a {
    color: #fff;
    text-decoration: none;
  }
`;
const StyledBCHLogo = styled.div<StyledLogoProps>`
  position: absolute;
  top: 0px;
  left: -45px;
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  display: flex;
  border: 2px solid #fff;
  border-radius: 45px;
  opacity: 1;
  z-index: 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
`;
export default Header;