import React, { useState } from 'react';
import styled from 'styled-components';
import { X, ChevronLeft, ChevronRight } from 'lucide-react';

interface ImageGalleryModalProps {
  images: string[];
  initialIndex: number;
  onClose: () => void;
}

const ImageGalleryModal: React.FC<ImageGalleryModalProps> = ({ images, initialIndex, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : images.length - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < images.length - 1 ? prevIndex + 1 : 0));
  };

  return (
    <ModalOverlay>
      <ModalContent>
        <ImageContainer>
          <CloseButton onClick={onClose}>
            <X size={24} />
          </CloseButton>
          <StyledImage src={images[currentIndex]} alt={`Image ${currentIndex + 1}`} />
          <PrevButton onClick={handlePrevious}>
            <ChevronLeft size={24} />
          </PrevButton>
          <NextButton onClick={handleNext}>
            <ChevronRight size={24} />
          </NextButton>
        </ImageContainer>
        <ThumbnailContainer>
          {images.map((image, index) => (
            <Thumbnail
              key={index}
              src={image}
              alt={`Thumbnail ${index + 1}`}
              isActive={index === currentIndex}
              onClick={() => setCurrentIndex(index)}
            />
          ))}
        </ThumbnailContainer>
      </ModalContent>
    </ModalOverlay>
  );
};

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;
const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 0.5rem;
  max-width: 80vw;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
`;
const ImageContainer = styled.div`
  position: relative;
`;
const StyledImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 60vh;
  object-fit: contain;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  &:hover {
    color: #333;
  }
`;
const NavButton = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: white;
  border: none;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  &:hover {
    background-color: #f0f0f0;
  }
`;
const PrevButton = styled(NavButton)`
  left: 0.5rem;
`;
const NextButton = styled(NavButton)`
  right: 0.5rem;
`;
const ThumbnailContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin-top: 1rem;
  overflow-x: auto;
`;
const Thumbnail = styled.img<{ isActive: boolean }>`
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  cursor: pointer;
  border: ${props => props.isActive ? '2px solid #3498db' : 'none'};
`;

export default ImageGalleryModal;