import styled from 'styled-components';

export const Button = styled.button`
  position: absolute;
  bottom: 0px;
  display: flex;
  background-color: #00956c;
  width: 100%;
  height: 30px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  border: 0px;
  border-radius: 0px 0px 8px 8px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &:hover {
    background-color: #00b381;
  }
`;