import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import { CartItem } from '../types';
import { v4 as uuidv4 } from 'uuid';
import BCHLogo from '../assets/img/bch.png';
import { useCart } from './CartContext';

function roundTo8DecimalPlaces(num: number): number {
    return Math.round(num * 1e8) / 1e8;
}

const Checkout: React.FC = () => {
const { cart, bchPrice } = useCart();
const { clearCart, amountDue } = useCart();


  //const [cart, setCart] = useState<CartItem[]>([]);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [phone, setPhone] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [paymentPending, setPaymentPending] = useState(false);

  const [orderID, setOrderID] = useState('');
  const [showIframe, setShowIframe] = useState(false);
/*
  useEffect(() => {
    const storedCart = localStorage.getItem('cart');
    if (storedCart) {
        const parsedCart = JSON.parse(storedCart);
        setCart(parsedCart);
        // Task 1: Calculate total amount
        const total = roundTo8DecimalPlaces(parsedCart.reduce((sum: number, item: CartItem) => sum + (item.price * item.qty), 0));
        setAmountDue(total);
    }
  }, []);
*/

  //watch for when prompt.cash iframe should display
  useEffect(() => {
    if (submitted && orderID !== '') {
      setShowIframe(true);
    }
  }, [submitted, orderID]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setPaymentPending(true);

    // Check if the cart is empty
    if (cart.length === 0) {
        alert('Your cart is empty. Please add items before placing an order.');
        return;
    }

    // Generate a new UUID for each submission
    const newOrderID = uuidv4().replace(/-/g, '');
    setOrderID(newOrderID);

    const order = {
      orderID: newOrderID,
      createdDate: new Date().toISOString(),
      items: cart,
      name,
      email,
      country,
      city,
      address,
      postalCode,
      phone,
      amountDue,
      bchAddress: 'tbd',
      paidAt: 'tbd',
      txHash: 'tbd',
      amountPaid: 'tbd',
      status: 'tbd'
    };
    console.log('created order: ', order);

    try {
      //const response = await fetch('http://localhost:3010/place-order', {
      const response = await fetch('https://bch.quest/place-order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(order),
      });
      const data = await response.json();
      setSubmitted(true);
      console.log(data);
      console.log(`Order placed successfully! Order ID: ${newOrderID}`)
      //alert(`Order placed successfully! Order ID: ${newOrderID}`);
      //setCart([]); // Update the cart state to reflect the empty cart
      //window.location.href = '/'; // Assuming the home page is at the root

    } catch (error) {
      console.error('Error:', error);
      alert('There was an error placing your order. Please try again.');
    }
  };

//watch for payment success message to prompt.cash iframe
  const handleMessage = useCallback(async (event: MessageEvent) => {
    // Make sure the message is from the expected origin
    if (event.origin !== "https://prompt.cash") return;
    console.log('message received: ', event);

    if (event.data.func === "onPromptPaid" && event.data.data) {
        console.log('event data: ', event.data);
        localStorage.removeItem('cart');
        clearCart();
        const promptResponse = event.data.data;

        const bchAddress = promptResponse.address;
        if (bchAddress != undefined) {
            console.log('new promptCash payment address: ', bchAddress);
            const paidAt = promptResponse.paid;
            const txHash = promptResponse.hash;
            const amountPaid = promptResponse.amount_crypto;
            const status = promptResponse.status;
            const txID = promptResponse.tx_id;

            // Update the stores server with the prompt.cash payment info
            try {
                //const response = await fetch('http://localhost:3010/update-order', {
                const response = await fetch('https://bch.quest/update-order', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        bchAddress,
                        paidAt,
                        txHash,
                        amountPaid,
                        status,
                        txID
                    }),
                });

                const data = await response.json();
                console.log("Server response:", data);

            } catch (error) {
                console.error("Error sending Bitcoin Cash address to server:", error);
            }
        } else {
            console.log('no prompt payment address detected in message');
        }

    } else {
        console.log('onPromptPaid not detected');
    }
  }, []);

  useEffect(() => {
    // Add event listener
    window.addEventListener('message', handleMessage);

    // Cleanup function
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

//////////////////////////////////////////// 
////////////////////// Cart Item Component
//////////////////////////////////////////// 
  interface CartItemProps {
    item: {
      name: string;
      price: number;
      image: string;
      qty: number;
    };
  }
  const CartItem: React.FC<CartItemProps> = ({ item }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedQty, setEditedQty] = useState(item.qty);
    const { addToCart } = useCart();
    const { removeFromCart } = useCart();
  
    const handleQuantityClick = () => {
      setIsEditing(true);
    };
  
    const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setEditedQty(parseInt(e.target.value) || 0);
    };
  
    const handleQuantityBlur = () => {
      setIsEditing(false);
      if (editedQty !== item.qty) {
        addToCart(item.name, item.price, item.image, editedQty - item.qty);
      }
      if (editedQty == 0) {
        removeFromCart(item.name);
      }
    };
  
    return (
      <StyledCartItem>
        <StyledCartImage logoUrl={item.image} />
        <StyledCartItemText>
          {item.name} <br />
          <StyledRow>
            <StyledBCHLogo size={20} />
            {roundTo8DecimalPlaces((item.price / bchPrice) * item.qty)}
          </StyledRow>
        </StyledCartItemText>

          <StyledQuantity onClick={handleQuantityClick}>
            {isEditing ? (
              <input
                type="number"
                value={editedQty}
                onChange={handleQuantityChange}
                onBlur={handleQuantityBlur}
                autoFocus
              />
            ) : (
              item.qty
            )}
          </StyledQuantity>
 
      </StyledCartItem>
    );
  };

  return (
    <CheckoutContainer>

      <StyledCheckoutDiv>
        <StyledCancelButton disabled={paymentPending} onClick={() => clearCart()}>Empty Cart</StyledCancelButton>
        <StyledTitleText>Checkout</StyledTitleText>
      </StyledCheckoutDiv>

      <CartItems>
        {cart.map((item, index) => (
            <CartItem key={index} item={item} />
        ))}
      </CartItems>

    <StyledShippingText>
        <span style={{fontSize: '20px', fontWeight: 'bold'}}>Shipping:</span> $5 USD to all locations. Automatically included in Purchase price below.
    </StyledShippingText>

      <Form onSubmit={handleSubmit}>
        <StyledInputRow>
            <label htmlFor="name">Name:</label>
            <Input 
            type="text" 
            id="name" 
            required 
            value={name} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
        </StyledInputRow>

        <StyledInputRow>
            <label htmlFor="email">Email:</label>
            <Input 
            type="email" 
            id="email" 
            required 
            value={email} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />
        </StyledInputRow>

        <StyledInputRow>
            <label htmlFor="country">Country:</label>
            <Input 
            type="text" 
            id="country" 
            required 
            value={country} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCountry(e.target.value)}
            />
        </StyledInputRow>

        <StyledInputRow>
            <label htmlFor="city">City:</label>
            <Input 
            type="text" 
            id="city" 
            required 
            value={city} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
            />
        </StyledInputRow>

        <StyledInputRow>
            <label htmlFor="address">Address:</label>
            <Input 
            type="text" 
            id="address" 
            required 
            value={address} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
            />
        </StyledInputRow>

        <StyledInputRow>
            <label htmlFor="Postal Code">Postal Code:</label>
            <Input 
            type="text" 
            id="postalCode" 
            required 
            value={postalCode} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostalCode(e.target.value)}
            />
        </StyledInputRow>

        <StyledInputRow>
            <label htmlFor="Phone">Phone:</label>
            <Input 
            type="text" 
            id="phone" 
            required 
            value={phone} 
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value)}
            />
        </StyledInputRow>

        <StyledPurchaseDiv>
            <StyledPurchaseButton type="submit" disabled={paymentPending}>Purchase <StyledBCHLogo size={20} />{amountDue}</StyledPurchaseButton>
        </StyledPurchaseDiv>
        
      </Form>

        {showIframe && 
            <>
            <StyledOrderID>Order ID: {orderID}</StyledOrderID>
            <StyledSubText>Error below? Give it a few seconds to refresh.</StyledSubText>
            <StyledPromptPayment>
                <iframe id="prompt-frame"
                    src={`https://prompt.cash/pay-frame?token=1439-Nn6Ay6fr&tx_id=${orderID}&amount=${amountDue}&currency=BCH&desc=BCH.Quest`}
                    width="400" height="800">
                </iframe>
                <StyledIssueText>
                    Any issues after paying? Contact us with your orders BCH amount, transactionID or the bitcoincash address you paid to.
                </StyledIssueText>
            </StyledPromptPayment>
            </>
        }

    </CheckoutContainer>
  );
};

interface ItemProps {
    logoUrl: string;
}
interface StyledLogoProps {
    size: number;
}

const CheckoutContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  margin-bottom: 25px;
  min-height: 75vh;
`;
const CartItems = styled.div`
  border: 0px solid blue;
  width: 100%;
  justify-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
`;
const Form = styled.form`
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  border: 0px solid red;
`;
const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const StyledInputRow = styled.div`
    display: flex;
    flex-direction: row;
    border: 0px solid orange;
    max-width: 300px;
    height: 25px;
`;
const StyledPromptPayment = styled.div`
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px
`;
const StyledCartItem = styled.div`
    position: relative;
    border: 1px solid #000;
    border-radius: 10px;
    width: 200px;
    height: 250px;
    justify-content: center;
    @media (max-width: 600px) {
        width: 150px;
        height: 200px;
    }
    &:hover {
        box-shadow: 0 0 15px rgba(10, 193, 143, 0.9);
    }
`;
const StyledCartItemText = styled.div`
    border: 0px solid red;
    display: flex;
    flex-direction: column;
    font-size: 14px;
    align-items: center;
    @media (max-width: 600px) {
        font-size: 12px;
    }
`;
const StyledOrderID = styled.div`
    border: 0px solid red;
    justify-content: center;
    display: flex;
    margin-top: 10px;
    font-size: 20px;
    font-weight: 600;
    align-items: center;
    @media (max-width: 600px) {
        font-size: 14px;
    }
`;
const StyledRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 3px;
`;
const StyledCheckoutDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 0px solid yellow;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
    gap: 5px;
`;
const StyledPurchaseDiv = styled.div`
    width: 100%;
    display: flex;
    border: 0px solid yellow;
    justify-content: center;
`;
const StyledIssueText = styled.div`
    width: 100%;
    display: flex;
    border: 0px solid yellow;
    justify-content: center;
    align-items: center;
    font-size: 14px;
`;
const StyledTitleText = styled.div`
    display: flex;
    border: 0px solid yellow;
    justify-content: center;
    align-items: center;
    font-size: 28px;
    font-weight: 700;
`;
const StyledShippingText = styled.div`
    display: flex;
    border: 2px dashed black;
    justify-content: center;
    text-align: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    height: 25px;
    margin-top: 5px;
    @media (max-width: 700px) {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        margin-left: 10px;
        margin-right: 10px;
        padding: 5px;
    }
`;
const StyledSubText = styled.div`
    display: flex;
    border: 0px solid yellow;
    height: 14px;
    justify-content: center;
    align-items: center;
    font-size: 14px;
`;
const StyledQuantity = styled.div`
    position: absolute;
    top: -5px;
    right: -5px;
    width: 30px;
    height: 30px;
    display: flex;
    border: 1px solid #000;
    border-radius: 45px;
    font-size: 16px;
    font-weight: 600;
    justify-content: center;
    align-items: center;
    color: #fff;
    background: #0AC18E;
    transition: box-shadow 0.3s ease;
    &:hover {
        box-shadow: 0 0 15px rgba(255, 0, 0, 0.9);
      }
    
      input {
        width: 100%;
        height: 100%;
        border: none;
        border-radius: 45px;
        background: #be5151;
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-align: center;
        outline: none;
        -moz-appearance: textfield;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
    }
`;
const StyledCartImage = styled.div<ItemProps>`
    background-image: url(${props => props.logoUrl});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-bottom: 1px solid #000;
    border-radius: 10px;
    width: 200px;
    height: 200px;
    @media (max-width: 600px) {
        width: 150px;
        height: 150px;
    }
`;
const StyledBCHLogo = styled.div<StyledLogoProps>`
  position: relative;
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  display: flex;
  border: 0px solid red;
`;
const StyledPurchaseButton = styled.button`
  display: flex;
  background-color: #00956c;
  width: 300px;
  height: 30px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  border: 3px outset #0ad59e;
  border-radius: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition-duration: 0.4s;
  &:hover {
    background-color: #00b381;
  }
  box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
`;
const StyledCancelButton = styled.button`
  display: flex;
  background-color: #a00;
  width: 150px;
  height: 30px;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: 600;
  border: 3px outset #fff;
  border-radius: 10px;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  gap: 10px;
  transition-duration: 0.4s;
  &:hover {
    background-color: #00b381;
  }
  box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
`;
export default Checkout;