import React, { createContext, useState, useEffect, useContext } from 'react';

interface CartItem {
    name: string;
    price: number;
    image: string;
    qty: number;
  }

interface CartContextType {
  cart: CartItem[];
  addToCart: (name: string, price: number, image: string, quantity: number) => void;
  removeFromCart: (itemId: string) => void;
  clearCart: () => void;
  amountDue: number;
  bchPrice: number;
}

const CartContext = createContext<CartContextType | undefined>(undefined);

export const CartProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [cart, setCart] = useState<CartItem[]>(() => {
    const savedCart = localStorage.getItem('cart');
    return savedCart ? JSON.parse(savedCart) : [];
  });
  const [amountDue, setAmountDue] = useState(0);
  const [bchPrice, setBCHPrice] = useState(0);

  function roundTo8DecimalPlaces(num: number): number {
    return Math.round(num * 1e8) / 1e8;
  }

    useEffect(() => {
        const fetchPrice = async () => {
            try {
                //const response = await fetch('http://localhost:3010/price');
                const response = await fetch('https://bch.quest/price');
                if (!response.ok) {
                throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setBCHPrice(data.price);
                //console.log('bch price is ' + data.price);
            } catch (error) {
                console.error('Error fetching BCH price:', error);
            }
        };

        // Fetch price immediately
        fetchPrice();

        // Set up interval to fetch price every 65 seconds
        const intervalId = setInterval(fetchPrice, 65000);

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
    }, []); // Empty dependency array means this effect runs once on mount and sets up the interval

  useEffect(() => {
    localStorage.setItem('cart', JSON.stringify(cart));
    const itemsCost = cart.reduce((acc, item) => acc + ((item.price / bchPrice) * item.qty), 0);
    const shipping = 5 / bchPrice;
    const total = itemsCost + shipping;
    setAmountDue(roundTo8DecimalPlaces(total));
  }, [cart, bchPrice]);

  const addToCart = (name: string, price: number, image: string, qty: number) => {
    setCart(prevCart => {
      const existingItemIndex = prevCart.findIndex(item => item.name === name);
      if (existingItemIndex > -1) {
        // If item exists, update its quantity
        const updatedCart = [...prevCart];
        updatedCart[existingItemIndex].qty += qty;
        return updatedCart;
      } else {
        // If item doesn't exist, add new item
        return [...prevCart, { name, price, image, qty }];
      }
    });
  };

  const removeFromCart = (name: string) => {
    setCart(prevCart => prevCart.filter(item => item.name !== name));
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart, clearCart, amountDue, bchPrice }}>
      {children}
    </CartContext.Provider>
  );
};

export const useCart = () => {
  const context = useContext(CartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};