import React, { useState } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import BCHLogo from '../assets/img/bch.png';
import { useCart } from './CartContext';
import ImageGalleryModal from './ImageGalleryModal';

interface StickerGridProps {
}
interface Sticker {
  name: string;
  price: number;
  images: string[];
}

const StickerGrid: React.FC<StickerGridProps> = ({}) => {
  const { cart, bchPrice } = useCart();
  const { clearCart } = useCart();
  const { addToCart } = useCart();
  const [modalOpen, setModalOpen] = useState(false);
  const [metric, setMetric] = useState(true);
  const [selectedSticker, setSelectedSticker] = useState<Sticker | null>(null);
  const stickers = [
    { name: "Pack 1", price: 11, images: ["assets/img/pack1.png"], metric: ``, imperial: ""},
    { name: "BCH Wizard", price: 2.25, images: ["assets/img/wizard.png", "assets/img/wizard2.png", "assets/img/wizard3.png"], metric: `3" x 2.2"`, imperial: "7.6cm x 5.5cm"},
    { name: "I Want You", price: 2.25, images: ["assets/img/iwantyou.png", "assets/img/iwantyou2.png", "assets/img/iwantyou3.png"], metric: `3" x 2"`, imperial: "7.6cm x 5cm"},
    { name: "Hotdog (3pc)", price: 1.50, images: ["assets/img/hotdog.png", "assets/img/hotdog2.png", "assets/img/hotdog3.png"], metric: `1.5" x 1"`, imperial: "3.8cm x 2.5cm"},
    { name: "Bear vs Bull", price: 2.25, images: ["assets/img/bearvsbull.png", "assets/img/bearvsbull2.png", "assets/img/bearvsbull3.png"], metric: `3.4" x 3"`, imperial: "8.6cm x 7.6cm"},
    { name: "Moonboy", price: 1.50, images: ["assets/img/moonboy.png", "assets/img/moonboy2.png"], metric: `1.6" x 1.6"`, imperial: "4cm x 4cm"},
    { name: "Plan B", price: 1.50, images: ["assets/img/planb.png", "assets/img/planb2.png", "assets/img/planb3.png"], metric: `3.1" x 1.4"`, imperial: "7.8cm x 3.5cm"},
    { name: "B Someone", price: 1.50, images: ["assets/img/bsomeone.png", "assets/img/bsomeone2.png"], metric: `3" x 1"`, imperial: "7.6cm x 2.5cm"},
    { name: "B The Change", price: 1.50, images: ["assets/img/bthechange.png", "assets/img/bthechange2.png", "assets/img/bthechange3.png"], metric: `3.2" x 1.25"`, imperial: "8.1cm x 3.1cm"},
    { name: "BCH Round", price: 1.50, images: ["assets/img/bchround.png", "assets/img/bchround2.png", "assets/img/bchround3.png"], metric: `2" x 1.9"`, imperial: "5cm x 4.8cm"},
    { name: "Cat1", price: 2.25, images: ["assets/img/cat1.png"], metric: `3" x 2"`, imperial: "7.6cm x 5cm"},
    { name: "Cat2", price: 2.25, images: ["assets/img/cat2.png"], metric: `3" x 2"`, imperial: "7.6cm x 5cm"},
    { name: "Cat3", price: 2.25, images: ["assets/img/cat3.png"], metric: `3" x 2"`, imperial: "7.6cm x 5cm"},
    { name: "Cat4", price: 2.25, images: ["assets/img/cat4.png"], metric: `3" x 2"`, imperial: "7.6cm x 5cm"},
  ];

  const [quantities, setQuantities] = useState<{ [key: string]: number }>(
    Object.fromEntries(stickers.map(sticker => [sticker.name, 1]))
  );

  const handleQuantityChange = (name: string, value: number) => {
    setQuantities(prev => ({ ...prev, [name]: Math.max(1, value) }));
  };

  const handleSizeChange = () => {
    setMetric(!metric);
  };

  const openModal = (sticker: Sticker) => {
    console.log('modal open');
    setSelectedSticker(sticker);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedSticker(null);
  };

  function roundTo8DecimalPlaces(num: number): number {
    return Math.round(num * 1e8) / 1e8;
  }

  return (
    <>
    <Grid>
      {stickers.map((sticker) => (
        <StickerItem key={sticker.name}>
          <StyledImage src={sticker.images[0]} alt={sticker.name} width="180" height="180" onClick={() => openModal(sticker)}/>
          <StyledTitle>{sticker.name}</StyledTitle>
          <StyledSize onClick={() => handleSizeChange()}>
            {metric ? sticker.metric : sticker.imperial}
          </StyledSize>
          <StyledPrice>
            Price:  ${sticker.price} USD<p/>
          </StyledPrice>
          <StyledPrice>
            <StyledBCHLogo size={24} />{roundTo8DecimalPlaces(sticker.price / bchPrice)}
          </StyledPrice>
          <StyledQty>
            <label htmlFor={`qty-${sticker.name}`}>Qty:</label>
            <input
              id={`qty-${sticker.name}`}
              type="number"
              min="1"
              value={quantities[sticker.name]}
              onChange={(e) => handleQuantityChange(sticker.name, parseInt(e.target.value))}
            />
          </StyledQty>
          <Button onClick={() => addToCart(sticker.name, sticker.price, sticker.images[0], quantities[sticker.name])}>Add to Cart</Button>
        </StickerItem>
      ))}
    </Grid>
    {modalOpen && selectedSticker && (
      <ImageGalleryModal
        images={selectedSticker.images} // You can add more images to this array if each sticker has multiple images
        initialIndex={0}
        onClose={closeModal}
      />
    )}
    </>
  );
};

interface StyledLogoProps {
  size: number;
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
  margin-top: 2em;
`;
const StyledQty = styled.div`
  margin-bottom: 5px;
  margin-top: 7px;
`;
const StickerItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  padding: 5px;
  text-align: center;
  padding-bottom: 30px;
  img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
  }
  &:hover {
    box-shadow: 0 0 15px rgba(10, 193, 143, 0.9);
  }
`;
const StyledTitle = styled.div`
  color: #000;
  font-size: 18px;
  font-weight: 600;
  height: 20px;
  border: 0px solid blue;
`;
const StyledSize = styled.div`
  color: #000;
  height: 16px;
  border: 0px solid red;
  font-size: 14px;
  font-weight: 400;
  &:hover {
    text-shadow: 0 0 7px rgba(234, 20, 20, 1);
  }
`;
const StyledPrice = styled.div`
  display: flex;
  height: 18px;
  gap: 5px;
  color: #000;
  font-size: 14px;
  font-weight: 600;
  border: 0px solid red;
  justify-content: center;
`;
const StyledImage = styled.img`
  display: flex;
  gap: 5px;
  border: 0px solid red;
  justify-content: center;
  align-item: center;
`;
const StyledBCHLogo = styled.div<StyledLogoProps>`
  position: relative;
  background-image: url(${BCHLogo});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: ${props => props.size}px;
  width: ${props => props.size}px;
  display: flex;
  border: 0px solid red;
`;
export default StickerGrid;