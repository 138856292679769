import React, { useState } from 'react';
import styled from 'styled-components';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import StickerGrid from './components/StickerGrid';
import CartModal from './components/CartModal';
import Checkout from './components/Checkout';
import { CartItem } from './types';
import { CartProvider } from './components/CartContext';


const App: React.FC = () => {
  return (
    <CartProvider>
      <Router>
        <AppContainer>
          <Header />
          <Routes>
            <Route path="/" element={
              <Main>
                <StickerGrid />

                <StyledTitle>About Us</StyledTitle>
                BCH.Quest is a passion project created by BCH supporters VinRadiant and Sayoshi Nakamario. <br/>
                Each sticker is made by us and includes an extra UV-protective lamination layer to help them last longer.
{/*
                <Button as={Link} to="/custom-designs">
                  Request Custom Designs
                </Button>
            */}
              </Main>
            } />
            <Route path="/checkout"  element={<Checkout/>}/>
          </Routes>

          <Footer />
        </AppContainer>
      </Router>
    </CartProvider>
  );
};

const AppContainer = styled.div`
  font-family: Arial, sans-serif;
  background-color: #f0f0f0;
  color: #333;
  line-height: 1.6;
  margin: 0;
  padding: 0;
`;
const Main = styled.main`
  max-width: 800px;
  margin: 0 auto;
  padding: 2em;
  display: flex;
  flex-direction: column;

`;
const Button = styled.button`
  display: inline-block;
  max-width: 200px;
  background-color: #00956c;
  color: white;
  padding: 0.5em 1em;
  text-decoration: none;
  border-radius: 4px;
  margin-top: 1em;
  cursor: pointer;
  border: none;
  &:hover {
    background-color: #00b381;
  }
`;
const StyledTitle = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  color: #000;
  font-size: 28px;
  font-weight: 600;
  height: 30px;
  margin-top: 20px;
  border: 0px solid blue;
`;
export default App;